<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="card card-custom card-stretch">
                    <div class="card-body pt-4">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <div class="row">
                                <div class="col-6">
                                    <div class="col-md-12">
                                        <v-text-field
                                            v-model="name"
                                            label="Tên chương trình*"
                                        ></v-text-field>
                                    </div>

                                    <div class="col-md-12">
                                        <v-file-input
                                            accept="image/*"
                                            append-icon="mdi-paperclip"
                                            prepend-icon=""
                                            truncate-length="30"
                                            label="Hình ảnh"
                                            @change="handle_upload_thumbnail"
                                        ></v-file-input>
                                    </div>

                                    <div class="col-md-12">
                                        <v-text-field
                                            v-model="voucher_code"
                                            label="Mã khuyến mại*"
                                        ></v-text-field>
                                    </div>
                                    <div class="col-md-12">
                                        <v-text-field
                                            v-model="sale_off"
                                            label="Giá trị khuyến mại*"
                                        ></v-text-field>
                                    </div>
                                    <div class="col-md-12">
                                        <v-text-field
                                            v-model="sale_max"
                                            label="Giá trị khuyến mại tối đa*"
                                        ></v-text-field>
                                    </div>

                                    
                                </div>
                                <div class="col-6">
                                    

                                    <div class="col-md-12">
                                        <v-datetime-picker dateFormat="dd-MM-yyyy" label="Thời gian bắt đầu chương trình*" v-model="start_time_event"> </v-datetime-picker>
                                    </div>
                                    <div class="col-md-12">
                                        <v-datetime-picker dateFormat="dd-MM-yyyy" label="Thời gian kết thúc chương trình*" v-model="end_time_event"> </v-datetime-picker>
                                    </div>

                                    <div class="col-md-12">
                                        <v-datetime-picker dateFormat="dd-MM-yyyy" label="Thời gian bắt đầu nhận thưởng*" v-model="start_time_claim"> </v-datetime-picker>
                                    </div>
                                    <div class="col-md-12">
                                        <v-datetime-picker dateFormat="dd-MM-yyyy" label="Thời gian kết thúc nhận thưởng*" v-model="end_time_claim"> </v-datetime-picker>
                                    </div>
                                    <div class="col-md-12">
                                        <v-textarea
                                            v-model="description"
                                            label="Mô tả"
                                            required
                                        ></v-textarea>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <v-btn class="ma-2 float-right" :loading="loading" color="info" @click="create_promotion">Thêm</v-btn>
                                    <v-btn class="ma-2 float-right" :loading="loading" @click="cancel">Huỷ</v-btn>
                                </div>
                            </div>
                        </v-form>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}

// for tabs
$color-transition-duration: 0.8s;
$accent-color: #2980b9;
$x-hover-color: #c0392b;
$smaller-nav-item-padding: 8px;
$icon-size: 0.875rem;

ul.nav-tabs {
    margin-top: 12px;
}

.card.tab-contents {
    border-top: none;
    border-radius: 0;
} 

.nav-link.tab {
    border-radius: 0;
    
    //Override the 16px Bootstrap default to give it a more tab-like feel
    padding-right: $smaller-nav-item-padding;
    
    span {
        transition: color $color-transition-duration;    
        color: black;
        opacity: 0.54;
        &:hover {
            color: $accent-color;
        }
    }
    
    &.active {
        span {
            opacity: 1;
        }
    }
           
    .icon-btn {
        margin-left: 6px;
        text-decoration: none;    
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        font-size: $icon-size;

            .fa {
                opacity: 0.54;
                transition: color $color-transition-duration;

                &:hover {
                    color: $x-hover-color;
                }
            }   
    }
    
    &.add-btn {
        padding-left: $smaller-nav-item-padding;        
        
        .icon-btn {
            color: $accent-color;
            margin: 0;    
        }
    }
}

.tab-icon:hover {
    color: #777;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    metaInfo: {
        title: 'Tạo chương trình khuyến mại'
    },
    data() {
        return {
            valid: true,
            name: "",
            banner: "",
            description: "",
            start_time_event: new Date(),
            end_time_event: new Date(),
            sale_off: "",
            sale_max: "",
            voucher_code: "",
            start_time_claim: new Date(),
            end_time_claim: new Date(),
            loading: false,
            name_rules: [],
            
        };
    },
    watch: {
        
    },
    computed: {
        
    },
    components: {

    },
    methods: {
        create_promotion() {
            this.loading = true;
            const default_time = Math.floor(new Date().getTime() / 1000)
            var payload = {
                Name: this.name,
                Banner: this.banner,
                Description: this.description,
                Sale_max: this.sale_max,
                Sale_of: this.sale_off,
                Voucher_code: this.voucher_code,
                Start_time_active: this.start_time_event ? Math.floor(this.start_time_event.getTime() / 1000) : default_time,
                End_time_active: this.end_time_event ? Math.floor(this.end_time_event.getTime() / 1000) : default_time,
                Start_time_claim: this.start_time_claim ? Math.floor(this.start_time_claim.getTime() / 1000) : default_time,
                End_time_claim: this.end_time_claim ? Math.floor(this.end_time_claim.getTime() / 1000) : default_time,
                toast: this.$root.$bvToast,
            }
            this.$store.dispatch('promotions/create_promotion', payload).then(() => {
                this.loading = false;
                this.$router.push({ name: 'promotions-index' });
            }).catch(() => {
                this.loading = false;
            });
        },
        cancel() {
            this.$router.push({ name: 'promotions-index' });
        },
        
        handle_upload_thumbnail(file) {
            var self = this;
            this.$store.dispatch('uploads/upload', {file: file}).then(res => {
                self.banner = res.data.url;
            }).catch(e => {
                console.log(e);
            });
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [ { title: "Livestream", route: "index" }, { title: "Thêm livestream" } ]);
        // this.$store.dispatch('products/get_all', {pageSize: 1000, pageIndex: 1, categoryId: -1});
    }
};
</script>



